
          $REACT_APP_PUBLIC_URL: "/";
        
@import './common/styles/libraries-config';
@import '~@node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@node_modules/react-widgets/dist/css/react-widgets.css';
@import './common/styles/main';

// prettier-ignore
body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none !important;
}

:root {
  --color-main-dark: #06818e;
  --color-main-dark-2: #06818e;
  --color-main: #06818e;
  --color-header-top: #06818e;
  --color-header-bottom: #06818e;
  --color-header-text: #fff;
  --color-header-link-underline: #ef5921;
  --color-link: #1673e6;
  --color-input-text: #ccc;
  --color-action: #16a3b2;
}


          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.login-form {
  background-color: white;

  &__container {
    width: 100%;
  }

  .login-form__input {
    border: 1px solid #ccc;
  }

  .form-input__label {
    font-size: $font-size-m !important;
  }

  &__submit-button-wrapper {
    margin: 10px 0;
    padding: 10px;

    &::before {
      content: '';
      flex: 1 0 50%;
    }
  }

  &__submit-button {
    padding: 14px 20px;
    background: #3a3a3c;
    font-size: $font-size-m !important;

    &:hover {
      text-decoration: underline;
    }
  }

  &__forgot-button {
    box-shadow: none;
    margin-left: 20px;
    background: none;
    color: #25a9e1;
    font-size: $font-size-m !important;
  }

  &__invalid-credentials {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 7px;
    color: red;
    font-size: 1rem;

    &__hidden {
      overflow: hidden;
      max-height: 0;
      margin: 0;
    }

    &__visible {
      max-height: 100px;
      transition: max-height 1s ease-out;
    }
  }
}


          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';
@import '~@styles/mixins.scss';

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 124px;
  background: var(--color-main-dark);
  color: #e1f7fa;

  &__logo {
    width: 70px;
    margin: 16px auto;
    fill: #fff;
  }

  &__logout {
    &:hover {
      cursor: pointer;
    }

    &__icon {
      margin-right: 10px;
      margin-left: 10px;
    }

    &__text {
      margin-right: 21px;
      font-size: 1.1em;
    }
  }

  &__navigation,
  &__navigation-bottom {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    margin: 0;
    color: #e1f7fa;
  }

  &__navigation-item:hover {
    background: #148b98;
  }

  &__navigation-bottom {
    flex: 0 0;
    margin-top: auto;
  }

  &__link {
    $this: &;

    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    cursor: pointer;

    &,
    &__icon {
      color: #e1f7fa;
    }

    &--active,
    &--active:hover {
      background: var(--color-action);
      color: #f7fbfc;

      #{$this} {
        &,
        &__icon {
          color: #f7fbfc;
        }
      }
    }

    &--logout {
      flex-direction: initial;
      justify-content: center;
      align-items: center;
      background: var(--color-action);
      color: #f7fbfc;
      font-size: 28px;
    }

    &__icon {
      margin: 0 auto;
      font-size: 28px;
    }

    &__label {
      margin: 0 auto;
      font-weight: bold;
      font-size: $font-size-sm;
      line-height: 1.5em;
    }
  }
}


          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.notification {
  $this: &;

  position: relative;
  left: 1000px;
  opacity: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  box-shadow: $card-shadow;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
  margin: 0 0 20px;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: $color-grey-80;
  color: $color-grey-99;
  transition: opacity 0.5s, left 0.5s ease-in-out;

  &__title {
    font-weight: 900;
    font-size: $font-size-m;
  }

  &__text {
    font-size: $font-size-ml;
    margin-right: 4px;
  }

  &__close-button {
    position: absolute;
    top: 5px;
    right: 7px;
    width: 20px;
    height: 20px;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;

    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &--red {
    background-color: #FAE6E7;
    color: #E35357;
    border: 1px solid #EB8386;
  }

  &--green {
    background-color: #E7F9E6;
    color: #0A560D;
    border: 1px solid #528B54;
  }

  &--blue {
    background-color: #5479ab;
  }

  &--yellow {
    background-color: #ffd951;
  }

  &--visible {
    left: 0;
    opacity: 1;
  }
}


          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/mixins.scss';
@import '~@styles/variables.scss';
@import url('//fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700');

@font-face {
  font-style: normal;
  font-family: 'Quattrocento Sans';
  src: url(../../assets/fonts/QuattrocentoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-weight: bold;
  font-family: 'Quattrocento Sans';
  src: url(../../assets/fonts/QuattrocentoSans-Bold.ttf) format('truetype');
}

@font-face {
  font-style: italic;
  font-family: 'Quattrocento Sans';
  src: url(../../assets/fonts/QuattrocentoSans-Italic.ttf) format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: bold;
  font-family: 'Quattrocento Sans';
  src:
    url(../../assets/fonts/QuattrocentoSans-BoldItalic.ttf)
    format('truetype');
}

html,
body,
#root {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

// USAGE
body,
button {
  font-family: 'Roboto', sans-serif;
}

body {
  cursor: default;
}

button {
  cursor: pointer;
}

a {
  &,
  &:link,
  &:visited {
    color: #1573e6;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}

.main-content {
  position: relative;
  z-index: 0;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-left: 124px;
}

.alert {
  position: relative;
  margin: 20px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;

  &.alert-warning {
    border-color: #ffeeba;
    background-color: #fff3cd;
    color: #856404;
  }

  &.alert-info {
    border-color: #bee5eb;
    background-color: #d1ecf1;
    color: #0c5460;
  }

  .alert-heading {
    color: inherit;
  }
}

ul.tabs {
  display: inline-block;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: $font-size-sm;

  @include sm() {
    margin-top: 0;
    margin-right: 20px;
  }

  li {
    position: relative;
    float: left;
    align-content: center;
    padding-top: 30px;
    list-style: none;
    vertical-align: center;

    button {
      display: block;
      height: 100%;
      margin: 0 4px;
      border-radius: 0;
      padding: 6px 14px;
      line-height: 1.5em;
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid #ef5921;
      }
    }

    &.active button {
      border-bottom: 2px solid #ef5921;
    }
  }
}

.saving-data {
  position: absolute;
  top: -4px;
  left: 0;
  z-index: 44000;
  width: 200px;
  box-shadow: 2px 2px 5px #555;
  border-radius: 5px;
  padding: 10px 30px;
  background-color: white;
  text-align: center;
}

.info__chip {
  clear: both;
  width: 100%;
  margin: 0 auto;
  font-weight: 200;
  font-size: $font-size-s;
  text-align: center;

  .chip {
    display: block;
    width: 36px;
    height: 36px;
    margin: 10px auto 30px;
    border: 1px solid #ccc;
    border-radius: 36px;
    padding: 0 0 9px 0;
    background-color: white;
    color: #606060;
    font-weight: 600;
    font-size: $font-size-l;
    text-align: center;
    cursor: pointer;

    &--loading {
      line-height: 36px;
    }

    &:hover {
      background-color: #eee;
      color: #333;
      font-weight: 600;
    }

    &--active {
      background-color: var(--color-main-dark);
      color: #eee;

      &:hover {
        opacity: 0.7;
        background-color: var(--color-main-dark);
        color: #eee;
      }
    }
  }
}

.loading {
  width: 100%;

  .line-content {
    height: 14px;
    margin-top: 14px;
    margin-left: 30px;
    background: #e9e9e9;
    animation: pulse 2s infinite;

    &.line-1 {
      width: 90%;
    }

    &.line-2 {
      width: 87%;
    }

    &.line-3 {
      width: 66%;
    }

    &.line-4 {
      width: 77%;
    }

    &.line-5 {
      width: 78%;
    }
  }

  .image-content {
    height: 120px;
    margin-left: 30px;
    border-radius: 5px;
    background: #ededed;
  }

  .h1-content {
    width: 72%;
    height: 30px;
    margin-top: 40px;
    margin-left: 30px;
    padding-left: 20px;
    background: #e9e9e9;
    animation: pulse 2s infinite;
  }
}

.task-list {
  width: 100%;
  box-shadow: 2px 2px 4px #ccc;
  margin: 0;
  padding: 0;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 0;
    background-color: White;
    list-style: none;
    font-size: $font-size-sm;

    &:hover {
      background-color: #fafafa;
      cursor: pointer;
    }

    &:first-child {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &-icon {
      margin: 0 10px 0 15px;
    }
  }
}

.clickable-card {
  cursor: pointer;
}


          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.notifications {
  position: fixed;
  top: 0;
  right: 2%;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 30px auto;

  &__wrapper {
    width: 100%;
  }
}
